import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Container = styled.div`
  visibility: ${props => (props.menuVisible ? "hidden" : "visible")};
  color: ${props => (props.menuVisible ? "black" : "white")};
  padding: 1rem;
  cursor: pointer;
  @media (min-width: 700px) {
    display: none;
  }
`

const HeaderHamburger = ({ onPress, menuVisible }) => (
  <Container onClick={onPress} menuVisible={menuVisible}>
    <FontAwesomeIcon icon="bars" size="lg" />
  </Container>
)

HeaderHamburger.propTypes = {
  siteTitle: PropTypes.string,
}

HeaderHamburger.defaultProps = {
  siteTitle: ``,
}

export default HeaderHamburger
