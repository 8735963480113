import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faIgloo,
  faChevronRight,
  faBars,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"
import {
  faGithub,
  faTwitter,
  faLinkedin,
  faStackOverflow,
  faMediumM,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons"

export const initializeIcons = () => {
  library.add(faIgloo)
  library.add(faGithub)
  library.add(faMediumM)
  library.add(faLinkedinIn)
  library.add(faTwitter)
  library.add(faLinkedin)
  library.add(faStackOverflow)
  library.add(faChevronRight)
  library.add(faBars)
  library.add(faTimes)
}
