import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Inconsolata|Noto+Serif');

  h1, h2, h3 {
    font-family: Inconsolata
  }

  html, body {
    max-width: 100%;
    overflow-x: hidden;
  }

  body {
    padding: 0;
    margin: 0;
    font-family: "Noto Serif";
    background: #282828;
    color: white;
  }
  a {
    text-decoration: none;

  }
  ul {
    margin: 0 auto;
    list-style-type: none;
  }
`
