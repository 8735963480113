import React from "react"
import styled from "styled-components"

const Wrapper = styled.span`
  margin-left: 0.5rem;
  font-size: ${props => props.fontSize || "1.4rem"};
  @media (min-width: 700px) {
    font-size: ${props => props.fontSize || "2rem"};
  }
`

const Emoji = props => (
  <Wrapper
    className="emoji"
    role="img"
    aria-label={props.label ? props.label : ""}
    aria-hidden={props.label ? "false" : "true"}
    fontSize={props.fontSize}
  >
    {props.symbol}
  </Wrapper>
)
export default Emoji
