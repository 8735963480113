import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const NavLinks = styled.ul`
  display: none;
  margin: 0;
  li {
    display: inline-block;
  }
  @media (min-width: 700px) {
    display: block;
  }
`

const StyledNavLink = styled(Link)`
  display: inline-block;
  color: white;
  text-decoration: none;
  font-family: Inconsolata;
  font-size: 1.3rem;
  margin: 0 1rem;
`

const StyledAnchorLink = styled.a`
  display: inline-block;
  color: white;
  text-decoration: none;
  font-family: Inconsolata;
  font-size: 1.3rem;
  margin: 0 1rem;
`

const HeaderNavLinks = () => (
  <NavLinks>
    <li>
      <StyledNavLink to="/portfolio" activeStyle={{ color: "#02d6c4" }}>
        Portfolio
      </StyledNavLink>
    </li>
    <li>
      <StyledAnchorLink
        href="https://medium.com/@stll.chung"
        target="_blank"
        rel="noreferrer"
      >
        Blog
      </StyledAnchorLink>
    </li>
    <li>
      <StyledNavLink to="/about" activeStyle={{ color: "#02d6c4" }}>
        About
      </StyledNavLink>
    </li>
  </NavLinks>
)

HeaderNavLinks.propTypes = {
  siteTitle: PropTypes.string,
}

HeaderNavLinks.defaultProps = {
  siteTitle: ``,
}

export default HeaderNavLinks
