import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import HeaderNavLinks from "./headerNavLinks"
import HeaderHamburger from "./headerHamburger"
import HeaderMenu from "./headerMenu"

const Container = styled.header`
  margin: 0.5rem auto;
  max-width: 700px;
`

const InnerContainer = styled.div`
  max-width: 960;
  padding: 0;
  h1 {
    margin: 0 0 0 1rem;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
`

const Logo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 1rem;
`

const Header = () => {
  const [isMenuOpen, setMenuVisibility] = useState(false)

  useEffect(() => {
    const bodyEl = document.querySelector("html")
    if (isMenuOpen) {
      bodyEl.style.cssText = "overflow-y: hidden;"
    } else {
      bodyEl.style.cssText = "overflow-y: auto;"
    }
  }, [isMenuOpen, setMenuVisibility])

  const toggleMenu = () => {
    setMenuVisibility(!isMenuOpen)
  }

  return (
    <Container>
      <InnerContainer>
        <Logo>
          <FontAwesomeIcon icon="chevron-right" size="lg" />
          <h1>
            <StyledLink to="/">{"Stella Chung"}</StyledLink>
          </h1>
        </Logo>
        <HeaderHamburger onPress={toggleMenu} menuVisible={isMenuOpen} />
        <HeaderMenu onPress={toggleMenu} isVisible={isMenuOpen} />
        <HeaderNavLinks />
      </InnerContainer>
    </Container>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
