import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import Emoji from "../components/emoji"

const IconContainer = styled.div`
  margin: 2rem 0;
`

const Title = styled.p`
  font-size: 1.2rem;
  @media (min-width: 500px) {
    font-size: 1.8rem;
  }
`

const Blurb = styled.p`
  font-size: 1rem;
  line-height: 2rem;

  @media (min-width: 500px) {
    font-size: 1.2rem;
    line-height: 2.5rem;
  }
`

const StyledIcon = styled(FontAwesomeIcon)`
  margin-right: 1.5rem;
  &:hover {
    color: #5dade2;
  }
`

const Content = styled.div`
  text-align: left;
  margin-left: 1rem;
`

const BlurbLink = styled(Link)`
  color: #5dade2;
  padding-bottom: 0.07rem;
  border-bottom: 2px solid #5dade2;
`

const AnchorLink = styled.a`
  color: #5dade2;
  padding-bottom: 0.07rem;
  border-bottom: 2px solid #5dade2;
`

const IndexPage = () => (
  <Layout>
    <SEO
      title="Stella Chung | Home"
      keywords={[`gatsby`, `application`, `react`]}
    />
    <Content>
      <Title>
        Hi there,
        <Emoji symbol="👋" label="waving-hand" fontSize="1.5rem" />
      </Title>
      <div>
        <Blurb>
          I'm a software engineer.
          <Emoji symbol="👩‍💻" label="technologist" />
          <Emoji symbol="🧙‍♀️" label="mage" />
        </Blurb>
        <Blurb>
          I make websites and apps.
          <Emoji symbol="💻" label="laptop computer" />
          <Emoji symbol="📱" label="smartphone" />
        </Blurb>
        <Blurb>
          I also{" "}
          <AnchorLink
            href="https://medium.com/@stll.chung"
            target="_blank"
            rel="noopener noreferrer"
          >
            write
          </AnchorLink>{" "}
          about what I learn.
        </Blurb>

        <Blurb />
        <Blurb>
          Check out some of the <BlurbLink to="/portfolio"> things</BlurbLink>{" "}
          I've been working on, or visit me around the web:
        </Blurb>
        <IconContainer>
          <a
            href="https://www.linkedin.com/in/chungstella/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StyledIcon icon={["fab", "linkedin-in"]} color="white" size="2x" />
          </a>
          <a
            href="https://github.com/stella-yc"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StyledIcon icon={["fab", "github"]} color="white" size="2x" />
          </a>
          <a
            href=" https://stackoverflow.com/story/stellachung"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StyledIcon
              icon={["fab", "stack-overflow"]}
              color="white"
              size="2x"
            />
          </a>
          {/*<a
          href="https://www.linkedin.com/in/chungstella/"
          target="_blank"
          rel="noreferrer"
        >
          <StyledIcon icon={["fab", "twitter"]} color="white" size="2x" />
        </a>
        */}
          <a
            href="https://medium.com/@stll.chung"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StyledIcon icon={["fab", "medium-m"]} color="white" size="2x" />
          </a>
        </IconContainer>
        <Blurb>
          Thanks for visiting! <Emoji symbol="😸" label="smiling cat" />
        </Blurb>
      </div>
    </Content>
  </Layout>
)

export default IndexPage
