/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Header from "./header"
import { GlobalStyle } from "../theme/globalStyle"
import { initializeIcons } from "../theme/faIcons"

initializeIcons()

const ContentWrapper = styled.div`
  margin: 2rem auto;
  max-width: 400px;
  padding: 0 1rem;
  padding-left: calc(100vw - 100%);
  @media (min-width: 900px) {
    max-width: 500px;
  }
`

const StyledFooter = styled.footer`
  margin: 2rem 0 0 0;
  text-align: center;
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <GlobalStyle />
        <Header siteTitle={data.site.siteMetadata.title} />
        <ContentWrapper>
          <main>{children}</main>
          <StyledFooter>
            © {new Date().getFullYear()}, Stella Chung
          </StyledFooter>
        </ContentWrapper>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
