import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Overlay = styled.div`
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  visibility: ${props => (props.isVisible ? "visible" : "hidden")};
  z-index: 1;
  overflow-y: hidden;
`

const Menu = styled.ul`
  position: absolute;
  top: 0;
  height: 100vh;
  background-color: rgba(242, 243, 244, 1);
  transition: all 0.5s ease;
  z-index: 2;
  padding-right: 1rem;
  visibility: ${props => (props.isVisible ? "visible" : "hidden")};
  right: ${props => (props.isVisible ? 0 : "-250px")};
  width: ${props => (props.isVisible ? "auto" : 0)};
`

const StyledNavLink = styled(Link)`
  color: black;
  text-decoration: none;
  font-family: Inconsolata;
  font-size: 1.4rem;
  margin: 0 1rem;
`

const CloseIcon = styled.div`
  text-align: right;
  cursor: pointer;
  padding: 1.5rem 0.6rem;
`
const MenuLinks = styled.ul`
  margin: 1rem 0;
  padding: 0;
  li {
    margin-bottom: 0.8rem;
  }
`

const StyledAnchorLink = styled.a`
  color: black;
  text-decoration: none;
  font-family: Inconsolata;
  font-size: 1.4rem;
  margin: 0 1rem;
`

const HeaderMenu = ({ onPress, isVisible }) => (
  <>
    <Overlay isVisible={isVisible} />
    <Menu isVisible={isVisible}>
      <CloseIcon onClick={onPress}>
        <FontAwesomeIcon icon="times" color="black" size="lg" />
      </CloseIcon>
      <MenuLinks>
        <li>
          <StyledNavLink to="/">Home</StyledNavLink>
        </li>
        <li>
          <StyledNavLink to="/portfolio">Portfolio</StyledNavLink>
        </li>
        <li>
          <StyledAnchorLink
            href="https://medium.com/@stll.chung"
            target="_blank"
            rel="noreferrer"
          >
            Blog
          </StyledAnchorLink>
        </li>
        <li>
          <StyledNavLink to="/about">About</StyledNavLink>
        </li>
      </MenuLinks>
    </Menu>
  </>
)

HeaderMenu.propTypes = {
  siteTitle: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
}

HeaderMenu.defaultProps = {
  siteTitle: ``,
}

export default HeaderMenu
